<template>
  <div class="mt-4">
    <vs-divider>Pekerjaan</vs-divider>

    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full h-72 overflow-auto">
        <vs-table :data="initData.spkAdjustD" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap w-24">#</vs-th>
            <vs-th class="whitespace-no-wrap">No Unit</vs-th>
            <vs-th class="whitespace-no-wrap">Nama Pekerjaan</vs-th>
            <vs-th class="whitespace-no-wrap">Bobot</vs-th>
            <vs-th class="whitespace-no-wrap">Nominal Nilai Dikurangi</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.no_unit || '-' }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.nama_item_progress_pekerjaan }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.bobot ? (item.bobot + '%') : '-' }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.nominal | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
      </div>
    </div>

    <!--modals-->
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
  </div>
</template>

<script>
import ModalApprovalProgress from '@/views/components/approval-progress/ModalApprovalProgress'
import _ from 'lodash'

export default {
  name: 'TabDetail',
  props: ['isActive', 'initData'],
  components: {
    ModalApprovalProgress
  },
  computed: {
    currentProgress () {
      const idSpkAdjust = this.$route.params.idSpkAdjust.toString()
      return _.find(this.initData.progress, item => item.id.toString() === idSpkAdjust)
    }
  },
  data () {
    return {
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      }
    }
  },
  methods: {
    showModalApproval (item) {
      this.modalApproval.type = 'SPP-ADJUST'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.spkAdjustApproval.activeTab
      this.$store.commit('approvals/spkAdjustApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    }
  }
}
</script>
